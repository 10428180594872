<template>
  <div
    class="list-group vue-select-list"
    :style="{ height: listSize, width: listWidth }"
    @scroll="onScroll"
    :dense="dense"
    ref="listComponent"
  >
    <v-text-field
      v-if="showSearch"
      style="padding: 10px 5px 0px 5px"
      label="Pesquisar"
      v-model="search"
    />
    <v-list-item
      class="list-group-item"
      :style="{ background: item.bgColor, flex: 'none' }"
      :key="item.idcadastroextraproduto"
      v-for="item in itens"
      @click="selectItem(item)"
    >
      <v-list-item-icon style="margin: auto">
        <input
          type="checkbox"
          v-model="item.selected"
          :disabled="isDisable(item)"
        />
      </v-list-item-icon>
      <v-list-item-content style="">
        <div class="item-content">
          <div>
            <span>{{ item.nome }}</span>
            <div v-if="item.obs">
              <small
                v-for="(o, i) in item.obs"
                :key="i"
                style="
              border: 1px solid lightgray;
              border-radius: 14px;
              font-size: 0.7em;
              padding: 0.2em 0.6em;
            "
                >{{ o }}</small
              >
            </div>
          </div>
          <v-tooltip top v-if="isDisable(item)">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">far fa-question-circle</v-icon>
            </template>
            {{ disableOptions.text }}
          </v-tooltip>
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import * as _ from 'lodash'

export default {
  name: 'ListComponent',
  props: {
    defaultItens: Array,
    listSize: String,
    showSearch: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    listWidth: {
      type: String,
      default: '300px'
    },
    disableOptions: Object
  },
  data: function() {
    return {
      selectedColor: '#F9F9F9',
      itens: this.defaultItens,
      search: ''
    }
  },
  watch: {
    defaultItens() {
      this.itens = this.defaultItens
    },
    search(val) {
      this.$emit('onSearch', val)
    }
  },
  methods: {
    isDisable(item) {
      return this.disableOptions && !_.get(item, this.disableOptions.key, null)
        ? true
        : false
    },
    selectItem(item) {
      if (this.isDisable(item)) {
        return
      }
      item.selected = !item.selected
      item.bgColor = item.selected ? this.selectedColor : ''
      this.$emit('selected-itens', this.itens)
    },
    onScroll(event) {
      const element = this.$refs.listComponent
      if (element) {
        const { clientHeight, scrollTop } = element
        const nativeElement = event.target
        const currentHeight = clientHeight + scrollTop
        const targetLoadHeight = nativeElement.scrollHeight
        if (currentHeight + 1 >= targetLoadHeight) {
          this.$emit('on-scroll')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-select-list {
  overflow-y: auto;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  .list-group-item {
    border-bottom: solid 1px #ccc;
  }
}

.item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1100px) {
  .list-group.vue-select-list {
    width: 66vw !important;
  }
}
</style>
