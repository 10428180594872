<template>
  <div class="select-list-component">
    <v-item-group class="select-list-search">
      <v-select
        label="Selecione forma de pesquisa"
        :items="searchTypes"
        outlined
        editable
        dense
        style="flex: 1;"
        :value="searchOption.value"
        @change="selectSearchOption"
      ></v-select>

      <v-text-field
        type="text"
        v-model="searchOption.search"
        placeholder="Pesquisar"
        style="flex: 2;"
        outlined
        dense
      ></v-text-field>
      <v-btn
        :value="1"
        @click="loadItems(true)"
        color="primario"
        style="flex: 1; padding: 20px 0"
        elevation="0"
      >
        Pesquisar
      </v-btn>
    </v-item-group>

    <div class="list">
      <div>
        <keep-alive>
          <ListComponent
            :default-itens="items"
            :list-size="listHeight"
            :disable-options="disableOptions"
            :showSearch="showSearchList"
            @on-scroll="loadItems(false)"
            @onSearch="searchObjects"
          >
          </ListComponent>
        </keep-alive>
      </div>
      <div class="btn-container">
        <FormButton
          text="Adicionar todos"
          @click="addItems(true)"
          type="submissao"
          outlined
          color="green"
          :disabled="disableButton"
          after-icon="fas fa-chevron-right"
        />
        <FormButton
          text="Adicionar"
          @click="addItems(false)"
          type="submissao"
          :disabled="disableButton"
          after-icon="fas fa-chevron-right"
        />
        <FormButton
          text="Remover"
          @click="removeItems(false)"
          type="perigo"
          :disabled="disableButton"
          before-icon="fas fa-chevron-left"
        />
        <FormButton
          text="Remover todos"
          @click="removeItems(true)"
          :disabled="disableButton"
          type="perigo"
          outlined
          color="red"
          before-icon="fas fa-chevron-left"
        >
        </FormButton>
      </div>
      <div>
        <keep-alive>
          <ListComponent
            :default-itens="itemsAddedd"
            :list-size="listHeight"
            :showSearch="showAddedListSearch"
            @on-scroll="loadAddedItems()"
            @onSearch="searchAddedObjects"
          >
          </ListComponent>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListComponent from './ListComponent'
import FormButton from '../../components/form/FormButton'
import ApiWeb from '../../service/api-web'
import * as _ from 'lodash'

export default {
  name: 'SelectListComponent',
  components: { ListComponent, FormButton },
  props: {
    value: Array,
    addAllItems: Array,
    searchEndpoint: {
      type: String,
      required: true
    },
    searchEndpointParams: {
      type: String,
      default: ''
    },
    loadEndpoint: {
      type: String,
      required: true
    },
    addAllEndpoint: {
      type: String,
      required: true
    },
    searchTypes: {
      type: Array
    },
    listHeight: {
      type: String,
      default: '400px'
    },
    itemsPerPage: {
      type: Number,
      default: 20,
      required: false
    },
    showSearchList: {
      type: Boolean,
      default: false
    },
    disableButton: {
      type: Boolean,
      default: false
    },
    showAddedListSearch: {
      type: Boolean,
      default: false
    },
    disableOptions: {
      type: Object,
      default: null,
      required: false
    },
    itemOptions: {
      type: Object,
      default: null,
      required: false
    }
  },
  data: function() {
    return {
      currentPage: 0,
      items: [],
      itemsAddedd: [],
      searchOption: {
        text: '',
        value: '',
        serch: ''
      },
      loadItemPos: 0,
      searchList: '',
      searchAddedList: ''
    }
  },
  computed: {
    ...mapGetters(['darkEnabled'])
  },
  watch: {
    searchAddedList() {
      this.debouncedLoadAddedItens(true)
    },
    addAllItems(val) {
      this.itemsAddedd = val
    }
  },
  mounted() {
    if (this.searchTypes && this.searchTypes.length > 0) {
      this.selectSearchType(this.searchTypes[0])
    }
    this.loadItems(true).then(() => this.loadAddedItems())
  },
  methods: {
    searchObjects(val) {
      this.searchList = val
    },
    searchAddedObjects(val) {
      this.searchAddedList = val
    },
    debouncedLoadItens: _.debounce(function() {
      this.loadItems(true)
    }, 500),
    debouncedLoadAddedItens: _.debounce(function() {
      this.loadAddedItems(true)
    }, 500),
    loadItems(clear = false) {
      return new Promise(resolve => {
        this.currentPage = clear ? 0 : this.currentPage + 1
        const params = `&${this.searchOption.value}=${this.searchOption.search}${this.searchEndpointParams}`
        const url = `${this.searchEndpoint}?page=${
          this.currentPage
        }&perpage=${this.itemsPerPage || 20}${params}`
        ApiWeb.api
          .get(url)
          .then(resp => {
            this.mapItems(resp.data, clear)
            resolve()
          })
          .catch(resolve)
      })
    },
    addItems(all = false) {
      this.$emit('fetching', true)
      if (all) {
        const params = `&${this.searchOption.value}=${this.searchOption.search}${this.searchEndpointParams}`
        const url = `${this.addAllEndpoint}?paginate=false${params}`
        ApiWeb.api
          .get(url)
          .then(resp => {
            this.$emit('add-all-items', this.itemsAddedd, resp.data)
            this.$emit('fetching', false)
            this.$emit('change', true)
          })
          .catch(() => this.$emit('fetching', false))
      } else {
        let selectedItems = (this.items || [])
          .filter(item => item.selected)
          .map(function(item) {
            return { ...item, selected: false }
          })
        this.$emit('fetching', false)
        this.$emit('change', true)
        this.prepareItensToUpdate(selectedItems, this.value)
      }
    },
    prepareItensToUpdate(selectedItems, originalArray) {
      if (!selectedItems.length) return
      let newValues = (this.itemsAddedd || []).concat(selectedItems)
      newValues.concat(originalArray)
      let keyid = _.get(this.itemOptions, 'id', null)
      let key = _.get(this.itemOptions, 'key', null)
      newValues = _.uniqBy(newValues, keyid)

      this.itemsAddedd = newValues.filter(o => o[key])
      this.$emit('input', newValues)
    },
    removeItems(all = false) {
      if (all) {
        this.itemsAddedd = []
        this.$emit('remove-all', true)
        this.$emit('change', true)
        return this.$emit('input', [])
      }
      this.$emit('fetching', true)
      let keyid = _.get(this.itemOptions, 'id', null)
      let itensRemovidos = this.itemsAddedd.filter(i => i.selected)
      const newValues = _.pullAllBy(this.value, itensRemovidos, keyid)
      this.itemsAddedd = _.remove(this.itemsAddedd, i => i.selected === false)
      this.$emit('input', newValues)
      this.$emit('fetching', false)
      this.$emit('remove-itens', itensRemovidos)
      this.$emit('change', true)
    },
    mapItems(items, clear = false) {
      let list = (items || []).map(item => this.mapItem(item))
      if (clear) {
        this.items = list
      } else {
        this.items = (this.items || []).concat(list)
      }
    },
    mapItem(item) {
      const { key, obs = [] } = this.itemOptions
      if (this.itemOptions && key) {
        let nome = _.get(item, key, '')
        let _obs = obs
          .map(o => {
            return item[o]
          })
          .filter(oo => oo)
        return {
          ...item,
          nome,
          obs: _obs,
          selected: false
        }
      }
      return {
        ...item,
        selected: false
      }
    },
    selectSearchOption(option) {
      this.searchOption.value = option
    },
    selectSearchType(type) {
      this.searchOption = {
        text: '',
        value: '',
        ...type
      }
    },
    loadAddedItems(isSearching = false) {
      if (isSearching) {
        this.loadItemPos = 0
      }
      if (this.loadEndpoint && this.value && this.value.length > 0) {
        let _this = this
        let keyid = _.get(_this.itemOptions, 'id', 'id')
        let payloadProp = _.get(this.itemOptions, 'payloadProp', 'id')
        let itemPerPage = this.loadItemPos + (this.itemsPerPage || 20)
        let listaIds = (this.value || [])
          .slice(
            this.loadItemPos,
            this.searchAddedList ? this.loadItemPos.length : itemPerPage
          )
          .map(item => {
            return _.get(item, keyid, '')
          })
        this.loadItemPos = itemPerPage
        const url = `${this.loadEndpoint}?search=${this.searchAddedList}`
        ApiWeb.api.post(url, { [payloadProp]: listaIds }).then(resp => {
          let oldItems = this.value || []
          const data = (resp.data || []).map(item => {
            let find = oldItems.find(olditem => {
              return _.get(item, keyid, null) == _.get(olditem, keyid, null)
            })
            if (find) {
              return this.mapItem(item)
            }
          })
          this.itemsAddedd = isSearching ? data : this.itemsAddedd.concat(data)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-list-search {
  display: flex;
  height: 55px;
  margin-bottom: 10px;
  padding: 5px 5px;
  .select {
    margin: unset;
  }
}
.select-list-component {
  .list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      margin: 2px 0;
    }
  }
}
@media (max-width: 1100px) {
  .select-list-component {
    .btn-container {
      flex-direction: row;
      justify-content: space-evenly;
    }
    .select-list-search {
      margin-bottom: unset;
    }

    .list {
      flex-direction: column;
      padding: 10px;
      div {
        padding: 10px;
        align-self: center;
      }
    }
  }
}
</style>
